import React, {Component} from "react";
import {FiCheck} from "react-icons/fi";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";


class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <React.Fragment>

        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Opportunity Brief Details"/>
        {/* End Pagehelmet  */}

        <Header headertransparent="header--transparent" colorblack="color--black" logoname="256x256.jpg"/>

        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--190 pt_md--100 pb_md--100 pt_sm--100 pb_sm--100 bg_image bg_image--4"
             data-black-overlay="5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100 pt_md--50 pt_sm--100">
                  <h2 className="title theme-gradient">Opportunity Briefs</h2>
                  <p>- feasible, viable, and desirable innovations -</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row row--35 sercice-details-content align-items-center">

                      <div className="col-lg-12 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <div className="section-title">
                            <span className="subtitle">Our service</span>
                            <h3 className="title">Opportunity Briefs: Your Gateway to Unexplored Success</h3>
                            <p className="description">In today's rapidly changing business landscape, identifying the next big opportunity
                              is more crucial than ever. As corporations strive to stay ahead of the curve, the need for a clear and concise
                              identification of feasible, viable, and desirable opportunities, to seize those becomes paramount.</p>
                            <p className="description">Introducing Opportunity Briefs - a tailored solution crafted by our seasoned
                              Strategic Opportunity Architect, leveraging fifteen years of unparalleled expertise in startup engineering and
                              entrepreneurial inventions.</p>
                          </div>
                          <div className="mt--30">
                            <h3 className="">Why Choose Opportunity Briefs?</h3>
                            <ul className="list-style--1 mt--30">
                              <li><FiCheck/> <strong>Efficiency</strong>: At just five pages, our Briefs are designed to deliver maximum
                                insight in a time-effective manner.
                              </li>
                              <li><FiCheck/> <strong>Expertise</strong>: Backed by a daily average of three-fourths of opportunity concept
                                generation, these Briefs are rooted in extensive industry knowledge.
                              </li>
                              <li><FiCheck/> <strong>Customization</strong>: Every Brief is curated to match your business needs,
                                ensuring alignment with your goals and strategies.
                              </li>
                              <li><FiCheck/> <strong>Growth-Oriented</strong>: Opportunity Briefs act as your roadmap, leading you toward
                                new horizons of growth and success.
                              </li>
                            </ul>
                          </div>
                          <div className="mt--30">
                            <h3 className="">How Opportunity Briefs Work:</h3>
                            <ol className="list-style--1 mt--30">
                              <li><FiCheck/> <strong>Understanding Your Needs</strong>: We begin by comprehending your business, objectives,
                                and the landscape you operate in.
                              </li>
                              <li><FiCheck/> <strong>Research & Analysis</strong>: Utilizing cutting-edge methodologies, we uncover
                                potentials that align with your strategic imperatives.
                              </li>
                              <li><FiCheck/> <strong>Crafting the Brief</strong>: The Opportunity Brief is created, distilling all insights
                                into a crisp, five-page document.
                              </li>
                              <li><FiCheck/> <strong>Ongoing Support</strong>: Our relationship doesn’t end with the delivery of the Brief;
                                we offer follow-up support to ensure successful implementation.
                              </li>
                            </ol>
                          </div>
                          <div className="mt--30">
                            <h3 className="">Embrace the Future with Confidence</h3>
                            <p className="description">Don't let the next big opportunity slip through your fingers. Opportunity Briefs are
                              the tool you need to harness the future, offering you the insights and guidance that can make the difference
                              between being a market leader or a follower.</p>
                            <p className="description">Ready to take the leap? <a className="link im-hover"
                                                                                  href="mailto:mail@bastianplatz.com" target="_blank" rel="noopener noreferrer">Contact
                              me</a> today to begin your journey towards untapped success with our expertly crafted Opportunity Briefs.</p>
                          </div>
                          <div className="mt--30">
                            <h3 className="">Free Opportunity Briefs</h3>
                            <p className="description">Check out these free example Opportunity Briefs:</p>
                            <ul className="list-style--1 mt--30">
                              <li><a href="https://bit.ly/oppbrief001" target="_blank" rel="noopener noreferrer"><FiCheck/> <strong>Opportunity
                                Brief #001</strong>: Better Reading Experience + ROI</a>
                              </li>
                              <li><a href="https://bit.ly/oppbrief002" target="_blank" rel="noopener noreferrer"><FiCheck/> <strong>Opportunity
                                Brief #002</strong>: Improvement for the Profile</a>
                              </li>
                              <li><a href="https://bit.ly/oppbrief003" target="_blank" rel="noopener noreferrer"><FiCheck/> <strong>Opportunity
                                Brief #003</strong>: Better Music Sharing</a>
                              </li>
                              <li><a href="https://bit.ly/oppbrief004" target="_blank" rel="noopener noreferrer"><FiCheck/> <strong>Opportunity
                                Brief #004</strong>: Nice Content Editor</a>
                              </li>
                              <li><a href="https://bit.ly/oppbrief005" target="_blank" rel="noopener noreferrer"><FiCheck/> <strong>Opportunity
                                Brief #005</strong>: Utilize LLMs Online</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}


        {/* Start Pricing Tbale Area
                <div className="rn-pricing-table-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <span className="subtitle">Our Budget Plan</span>
                                    <h2 className="title">Pricing Plan</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Free</h4>
                                            <div className="pricing">
                                                <span className="price">$45</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 PPC Campaigns</li>
                                                <li><FiCheck /> Digital Marketing</li>
                                                <li><FiCheck /> Marketing Agency</li>
                                                <li><FiCheck /> Seo Friendly</li>
                                                <li><FiCheck /> UI/UX designs</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Business</h4>
                                            <div className="pricing">
                                                <span className="price">$45</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 PPC Campaigns</li>
                                                <li><FiCheck /> Digital Marketing</li>
                                                <li><FiCheck /> Marketing Agency</li>
                                                <li><FiCheck /> Seo Friendly</li>
                                                <li><FiCheck /> UI/UX designs</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Advanced</h4>
                                            <div className="pricing">
                                                <span className="price">$99</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 PPC Campaigns</li>
                                                <li><FiCheck /> Digital Marketing</li>
                                                <li><FiCheck /> Marketing Agency</li>
                                                <li><FiCheck /> Seo Friendly</li>
                                                <li><FiCheck /> UI/UX designs</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                 Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo/>

      </React.Fragment>
    );
  }
}

export default ServiceDetails;
